import React, { useCallback } from 'react';
import PromptInput from './PromptInput';
import type { ModalDetailsType, PromptModalDetailsType, ToolModalDetailsType } from './modalsbar-types';
import { useInput } from '../hooks/useInput';

type ParamsType = {
  selectedPrompt: PromptModalDetailsType;
  inputPrompt: string;
  onClose: {
    (): void;
  };
};

function DefaultModal({ selectedPrompt, inputPrompt, onClose }: ParamsType) {
  const { runInputPrompt } = useInput();
  const onFormSubmit = useCallback(
    (value: string) => {
      runInputPrompt({ inputPrompt: value, onSent: onClose });
    },
    [runInputPrompt, onClose]
  );

  return (
    <div>
      <div className="backdrop" onClick={onClose}></div>
      <PromptInput
        prompt={selectedPrompt.prompt as string}
        description={selectedPrompt.description}
        howItWorks={selectedPrompt.howItWorks}
        placeholderText1={selectedPrompt.placeholderText1}
        placeholderText2={selectedPrompt.placeholderText2}
        onClose={onClose}
        UserInput2={selectedPrompt.UserInput2}
        inputPrompt={inputPrompt}
        onFormSubmit={onFormSubmit}
      />
    </div>
  );
}

function PromptInputFactory(
  selectedPrompt: ModalDetailsType,
  inputPrompt: string,
  onClose: { (): void },
  onDone: { (done: boolean): void }
) {
  const prompt = selectedPrompt as unknown;
  return (prompt as ToolModalDetailsType).renderModal ? (
    (prompt as ToolModalDetailsType).renderModal(
      (prompt as ToolModalDetailsType).suggested,
      inputPrompt,
      onClose,
      onDone
    )
  ) : (
    <DefaultModal selectedPrompt={prompt as PromptModalDetailsType} inputPrompt={inputPrompt} onClose={onClose} />
  );
}

export default PromptInputFactory;
