import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useChats } from '../hooks/useChats';
import { makeBaseRequest, makeRequest } from '../data/makeRequest';
import { useRequest } from '../hooks/useRequest';
import type { ChatType, FileInChat } from '../data/chats-type';
import { checkVectorStoreRequest, getRequest } from '../data/llm/openai';
import { DefinedReaderReport, ReportCollection } from '../utils/file-uploads/file-uploads';
import type { DisplayUpload } from '../utils/file-uploads/display-uploads';
import { FEFileAssistant, VectorStoreFile } from '../data/chats-remote';
import { progressPercents } from '../utils/math';
import LLMS, { DEFAULT_LLM_KEY } from '../data/llm';
import { SelectedChatContext } from './SelectedChatContext';
import { _raiseIfNotOk } from '../utils/responses';

const getShowFileUploadsValue = (llmKey?: string | undefined) => LLMS.llm(llmKey || DEFAULT_LLM_KEY).hasFileUploads;

export type UploadedFile = VectorStoreFile;

type ChangingSelection = {
  changingSelection?: boolean;
};

export type UploadedFileState = (UploadedFile | Omit<UploadedFile, 'fileContent'>) & {
  changingSelection?: boolean;
  isLegacy: boolean;
};

type StateType = {
  changeFileSelection: {
    (chat: ChatType, file: UploadedFile & ChangingSelection, selectLegacy?: boolean): Promise<unknown>;
  };
  filesInUploadReports: Array<DisplayUpload>;
  isUploadingFile: boolean;
  uploadedFiles: Array<UploadedFileState>;
  uploadFiles: (files: FileList | Array<File>) => Promise<Array<FileInChat>>;
  uploadingProgress: number;
  showFileUploads: boolean;
};

const initialState: StateType = {
  changeFileSelection: () => Promise.resolve(),
  // selectLegacyFile: () => Promise.resolve(),
  filesInUploadReports: [],
  isUploadingFile: false,
  uploadedFiles: [],
  uploadingProgress: -1,
  uploadFiles: () => Promise.resolve([]),
  showFileUploads: getShowFileUploadsValue()
};

export const ChatFileUploadContext = createContext<StateType>(initialState);

const fileUploadRequest = async (formData: FormData): Promise<DefinedReaderReport> => {
  const response = await makeBaseRequest(`${process.env.REACT_APP_BACKEND_URL}/vector-store-upload-file-reported`, {
    method: 'POST',
    body: formData
  });
  _raiseIfNotOk(response);
  return (await response.json()) as DefinedReaderReport;
};

const readPageRequest = async (file: File, pageIndex: number): Promise<string> => {
  const formData = new FormData();
  formData.append('page', String(pageIndex));
  formData.append('file', file);
  const readerResponse = await makeBaseRequest(`${process.env.REACT_APP_BACKEND_URL}/read-file-page`, {
    method: 'POST',
    body: formData
  });
  _raiseIfNotOk(readerResponse);
  const responseData = await readerResponse.json();
  const { content } = responseData;
  return content;
};

const readAiPageRequest = async (file: File, pageIndex: number) => {
  const formData = new FormData();
  formData.append('page', String(pageIndex));
  formData.append('file', file);
  const readerResponse = await makeBaseRequest(`${process.env.REACT_APP_BACKEND_URL}/read-file-page-with-ai`, {
    method: 'POST',
    body: formData
  });
  const responseData = await readerResponse.json();
  if (!readerResponse.ok) {
    throw new Error(responseData.error);
  }
  const { reader_url } = responseData;
  return reader_url;
};

export const uploadPagesRequest = async (fileName: string, vectorStoreId: string, pageContentList: Array<string>) => {
  const response = await makeRequest(`${process.env.REACT_APP_BACKEND_URL}/vector-store-pages-upload`, {
    filename: fileName,
    vector_store_id: vectorStoreId,
    page_content_list: pageContentList
  });
  const data: { vector_store_file_id: string; is_finished: boolean; result_content: string } = await response.json();
  const { vector_store_file_id: vectorStoreFileId, is_finished: isFinished, result_content: resultContent } = data;
  return { vectorStoreFileId, isFinished, resultContent };
};

const checkVectorStoreFileUpload = async (vectorStoreId: string, vectorStoreFileId: string) => {
  const response = await makeRequest(
    `${process.env.REACT_APP_BACKEND_URL}/vector-store-upload-check/${vectorStoreId}/${vectorStoreFileId}`,
    {},
    { method: 'GET', body: undefined }
  );
  const { is_finished: isFinished }: { is_finished: boolean } = await response.json();
  return isFinished;
};

export const createUploadChecker = async (vectorStoreId: string, vectorStoreFileId: string) => {
  const checkUploadingTillFinished = async (): Promise<true> => {
    const finished = await checkVectorStoreFileUpload(vectorStoreId, vectorStoreFileId);
    if (!finished) {
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          checkUploadingTillFinished()
            .then(() => {
              resolve(true);
            })
            .catch((checkerError) => {
              reject(checkerError);
            });
        }, 500);
      });
    }
    return true;
  };
  await checkUploadingTillFinished();
  await createVectorStoreInProgressChecker(vectorStoreId);
};

const checkReadingRequest = async (url: string) => {
  const response = await makeRequest(`${process.env.REACT_APP_BACKEND_URL}/check-page-reading`, { url });
  const data: { finished: boolean; content: string } = await response.json();
  const { finished: isFinished, content } = data;
  return { isFinished, content };
};

const createPageAIReadingChecker = async (checkUrl: string) => {
  const checkPageTillFinished = async (): Promise<string> => {
    const { isFinished, content } = await checkReadingRequest(checkUrl);
    if (isFinished) {
      return content;
    } else {
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          checkPageTillFinished()
            .then((content) => {
              resolve(content);
            })
            .catch((error) => {
              reject(error);
            });
        }, 600);
      });
    }
  };
  return await checkPageTillFinished();
};

const checkVectorInProgressRequest = async (vectorStoreId: string): Promise<boolean> => {
  const response = await getRequest(`/check-vector-store-in-progress/${vectorStoreId}`);
  _raiseIfNotOk(response);
  const { in_progress: inProgress } = await response.json();
  return inProgress;
};

const createVectorStoreInProgressChecker = async (vectorStoreId: string) => {
  const checkVectorStoreTillComplete = async (): Promise<any> => {
    const inProgress = await checkVectorInProgressRequest(vectorStoreId);
    if (!inProgress) {
      return;
    } else {
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          checkVectorStoreTillComplete()
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(error);
            });
        }, 500);
      });
    }
  };
  return await checkVectorStoreTillComplete();
};

const resetAssistantStoreRequest = async (chatId: string, fileIds: Array<string>) => {
  const response = await makeRequest(`${process.env.REACT_APP_BACKEND_URL}/reset-vector-store/${chatId}`, {
    file_ids: fileIds
  });
  _raiseIfNotOk(response);
  const { vector_store_id: vectorStoreId, thread_id: threadId, assistant_id: assistantId } = await response.json();
  return { vectorStoreId, threadId, assistantId };
};

const canChangeSelection = (uploadedFiles: Array<UploadedFileState>, isWaitingApi: boolean): boolean => {
  if (uploadedFiles.some(({ changingSelection }) => changingSelection)) {
    throw new Error('Cannot start selection changing when another one is in progress.');
  }
  return !isWaitingApi;
};

type ParamsType = {
  children: ReactNode;
};

export function ChatFileUploadProvider({ children }: ParamsType) {
  const { responseFromAPI, setResponseFromAPI, reauthRequest } = useRequest();
  const [uploadedFiles, setUploadedFiles] = useState<Array<UploadedFileState>>([]);
  const [reportsCollections, setReportsCollections] = useState<Array<ReportCollection>>([]);
  const [filesInUploadReports, setFilesInUploadReports] = useState<Array<DisplayUpload>>([]);
  const [uploadingProgress, setUploadingProgress] = useState<number>(-1);
  const { replaceChat } = useChats();
  const { selectedChat: chat } = useContext(SelectedChatContext);
  const [showFileUploads, setShowFileUploads] = useState(getShowFileUploadsValue(chat?.llm));
  const [filesHover, setFilesHover] = useState(false);
  const fileDragEnterRef = useRef<HTMLDivElement>(null);
  const fileDropRef = useRef<HTMLDivElement>(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const replaceReports = useCallback((index: number, reports: ReportCollection) => {
    setReportsCollections((collections) =>
      [...collections].map((collection, collectionIndex) => (index === collectionIndex ? reports : collection))
    );
  }, []);

  const readPagesOnByOne = useCallback(
    async (file: File, totalPages: number, onReadFinishedPage: { (content: string): void }): Promise<void> => {
      return await new Promise<void>((resolve, reject) => {
        const readOnePage = (file: File, pageIndex: number) => {
          readPageRequest(file, pageIndex)
            .then((content) => {
              onReadFinishedPage(content);
              if (pageIndex < totalPages - 1) {
                readOnePage(file, pageIndex + 1);
              } else {
                resolve();
              }
            })
            .catch((error) => {
              reject(error);
            });
        };
        readOnePage(file, 0);
      });
    },
    []
  );

  const readAIPagesOnByOne = useCallback(
    async (
      file: File,
      pageIndex: number,
      totalPages: number,
      onContent: { (content: string): void }
    ): Promise<Array<string>> => {
      const contentList: Array<string> = [];
      for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
        const readyPageContent = await new Promise<string>((resolve, reject) => {
          readAiPageRequest(file, pageIndex)
            .then((readerUrl) => {
              createPageAIReadingChecker(readerUrl)
                .then((content) => {
                  resolve(content);
                })
                .catch((readerError) => {
                  reject(readerError);
                });
            })
            .catch((readError) => {
              reject(readError);
            });
        });
        onContent(readyPageContent);
        contentList.push(readyPageContent);
      }
      return contentList;
    },
    []
  );

  const uploadPages = useCallback(
    async (
      reportIndex: number,
      reportsCollection: ReportCollection,
      vectorStoreId: string
    ): Promise<ReportCollection> => {
      const pageContentList = reportsCollection.pagesContentList;
      type UploadResult = { vectorStoreFileId: string; fileContent: string };
      await new Promise<UploadResult>((resolve, reject) => {
        uploadPagesRequest(reportsCollection.originalFileName, vectorStoreId, pageContentList)
          .then(({ vectorStoreFileId, isFinished, resultContent }) => {
            replaceReports(
              reportIndex,
              reportsCollection.withReport({
                upload_status: 'start_saving',
                vector_store_file_id: vectorStoreFileId,
                file_content: resultContent
              })
            );
            const result = { vectorStoreFileId, fileContent: resultContent };
            createUploadChecker(vectorStoreId, vectorStoreFileId)
              .then(() => {
                resolve(result);
              })
              .catch((error) => {
                console.error(error);
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
      return reportsCollection.withReport({
        upload_status: 'finished'
      });
    },
    [replaceReports]
  );

  const setUploadedFilesByChat = useCallback((chat: ChatType | null) => {
    const assistantFiles = [...(chat?.fileAssistant?.vectorStoreFiles || [])].map(({ ...vsFile }) => ({
      ...vsFile,
      isLegacy: false
    }));

    const legacyFiles = (chat?.files || []).map((file) => ({ ...file, isLegacy: true }));
    const filesToSet = [...assistantFiles, ...legacyFiles].map((file) => ({
      ...file,
      changingSelection: false
    }));

    setUploadedFiles(() => filesToSet);
  }, []);

  const runFileUploadsOneByOne = useCallback(
    (files: FileList | Array<File>, vectorStoreId: string, chatId: string): Promise<Array<ReportCollection>> => {
      const initReports: Array<ReportCollection> = [];
      for (const file of files) {
        if (file) {
          initReports.push(
            new ReportCollection({
              upload_status: 'pending',
              original_filename: file.name,
              vector_store_id: vectorStoreId
            })
          );
        }
      }
      setReportsCollections(() => [...initReports]);
      const uploadSingleFile = (
        fileIndex: number,
        reportIndex: number,
        resultReports: Array<ReportCollection>,
        resolveAll: { (result: Array<ReportCollection>): void }
      ) => {
        if (fileIndex >= files.length) {
          resolveAll(resultReports);
          return;
        }
        const file = files[fileIndex];
        if (!file) {
          uploadSingleFile(fileIndex + 1, reportIndex, resultReports, resolveAll);
          return;
        }
        const formData = new FormData();
        formData.append('file', file);
        const fileReports = initReports[reportIndex];
        const uploadPromise: Promise<ReportCollection> = new Promise((resolve) => {
          reauthRequest(() => fileUploadRequest(formData))
            .then((definedReaderResponse) => {
              replaceReports(reportIndex, fileReports.withReport(definedReaderResponse));
              const onContent = (content: string) => {
                replaceReports(
                  reportIndex,
                  fileReports.withReport({ upload_status: 'finished_page_reading', content })
                );
              };
              const readPromise = fileReports.requiresAI
                ? readAIPagesOnByOne(file, 0, fileReports.totalPages, onContent)
                : readPagesOnByOne(file, fileReports.totalPages, onContent);

              readPromise
                .then(() => {
                  uploadPages(reportIndex, fileReports, vectorStoreId)
                    .then((uploadedReports) => {
                      replaceReports(reportIndex, uploadedReports);
                      resolve(uploadedReports);
                    })
                    .catch((uploadError) => {
                      console.error(uploadError);
                      resolve(
                        fileReports.withReport({
                          upload_status: 'failed',
                          message: 'Error uploading file'
                        })
                      );
                    });
                })
                .catch((readPagesError) => {
                  console.error(readPagesError);
                  resolve(
                    fileReports.withReport({
                      upload_status: 'failed',
                      message: 'Error reading page'
                    })
                  );
                });
            })
            .catch((error) => {
              console.error(error);
              resolve(fileReports.withReport({ upload_status: 'failed', message: 'Cannot upload file' }));
            });
        });
        uploadPromise
          .then((reports) => {
            resultReports.push(reports);
            uploadSingleFile(fileIndex + 1, reportIndex + 1, resultReports, resolveAll);
          })
          .catch((error) => {
            console.error(error);
            uploadSingleFile(fileIndex + 1, reportIndex + 1, resultReports, resolveAll);
          });
      };

      return new Promise((resolveAll) => {
        uploadSingleFile(0, 0, [], resolveAll);
      });
    },
    [readAIPagesOnByOne, readPagesOnByOne, reauthRequest, replaceReports, uploadPages]
  );

  const uploadFiles = useCallback(
    async (files: FileList | Array<File>) => {
      if (!chat || responseFromAPI) {
        return [];
      }

      setIsUploadingFile(() => true);
      setResponseFromAPI(true);

      const { vectorStoreId } = await reauthRequest(() => checkVectorStoreRequest(chat.id as string));
      const reportsCollectionList = await runFileUploadsOneByOne(files, vectorStoreId, chat.id as string);
      const reportsAsFiles: Array<VectorStoreFile> = reportsCollectionList
        .filter((reports) => !reports.isFailed)
        .map((reportsCollection) => ({
          fileId: reportsCollection.vectorStoreFileId,
          fileName: reportsCollection.originalFileName,
          fileContent: reportsCollection.fileContent,
          selected: true
        }));
      const chatFiles = [...(chat.fileAssistant?.vectorStoreFiles || []), ...reportsAsFiles];
      const selectedChatFilesIds = chatFiles.filter(({ selected }) => selected).map(({ fileId }) => fileId);
      const {
        vectorStoreId: newVectorStoreId,
        threadId: newThreadId,
        assistantId: newAssistantId
      } = await resetAssistantStoreRequest(chat.id as string, selectedChatFilesIds);
      try {
        const fileAssistant = {
          assistantId: newAssistantId,
          threadId: newThreadId,
          vectorStoreId: newVectorStoreId,
          vectorStoreFiles: chatFiles
        };

        const updatedChat = {
          ...chat,
          fileAssistant
        };

        await replaceChat(updatedChat).unwrap();
        setUploadedFilesByChat(updatedChat);
        setReportsCollections(() => reportsCollectionList.filter((reports) => reports.isFailed));
      } catch (error) {
        console.error('Error in handleFileUpload:', error);
      } finally {
        setIsUploadingFile(() => false);
        setResponseFromAPI(false);
      }
      return [];
    },
    [
      chat,
      reauthRequest,
      replaceChat,
      responseFromAPI,
      runFileUploadsOneByOne,
      setResponseFromAPI,
      setUploadedFilesByChat
    ]
  );

  const executeChangeFileSelection = useCallback(
    async (chatData: ChatType, file: UploadedFile, selectingLegacy: boolean) => {
      setUploadedFiles((files) =>
        [...files].map((uploadedFile) =>
          uploadedFile.fileId === file.fileId ? { ...uploadedFile, changingSelection: true } : uploadedFile
        )
      );

      let chat: ChatType;
      let vectorStoreFiles: Array<VectorStoreFile>;
      let assistantId: string | null = null;
      let newVectorStoreId: string;
      let newThreadId: string;

      if (!selectingLegacy) {
        chat = { ...chatData };
        const fileAssistant = chat.fileAssistant as FEFileAssistant;
        assistantId = fileAssistant.assistantId;
        vectorStoreFiles = fileAssistant.vectorStoreFiles;
      } else {
        const { files, ...chatInfo } = chatData;
        chat = { ...chatInfo, files: files.filter((legacyFile) => legacyFile.fileId !== file.fileId) };
        if (chat.fileAssistant) {
          assistantId = chat.fileAssistant.assistantId;
          vectorStoreFiles = chat.fileAssistant.vectorStoreFiles;
        } else {
          vectorStoreFiles = [];
        }
      }

      const chatId = chat.id as string;
      const newSelected = !file.selected;
      const replaceFiles = [...vectorStoreFiles];
      if (selectingLegacy) {
        replaceFiles.push({ ...file, selected: true });
      } else {
        replaceFiles.splice(
          vectorStoreFiles.findIndex(({ fileId: chatFileId }) => chatFileId === file.fileId),
          1,
          { ...file, selected: newSelected }
        );
      }
      const replaceFileIds = replaceFiles.filter(({ selected }) => selected).map(({ fileId }) => fileId);
      if (assistantId) {
        const {
          vectorStoreId,
          threadId,
          assistantId: realAssistantId
        } = await reauthRequest(() => resetAssistantStoreRequest(chatId, replaceFileIds));
        newVectorStoreId = vectorStoreId;
        newThreadId = threadId;
        assistantId = realAssistantId as string;
      } else {
        const {
          assistantId: newAssistantId,
          threadId,
          vectorStoreId
        } = await reauthRequest(() => checkVectorStoreRequest(chatId, replaceFileIds));
        assistantId = newAssistantId as string;
        newVectorStoreId = vectorStoreId;
        newThreadId = threadId;
      }

      await createVectorStoreInProgressChecker(newVectorStoreId);
      await replaceChat({
        ...chat,
        fileAssistant: {
          assistantId,
          threadId: newThreadId,
          vectorStoreId: newVectorStoreId,
          vectorStoreFiles: replaceFiles
        }
      }).unwrap();
    },
    [replaceChat, reauthRequest]
  );

  const changeFileSelection = useCallback(
    async (chatData: ChatType, file: UploadedFile, selectingLegacy = false) => {
      if (!canChangeSelection(uploadedFiles, responseFromAPI)) {
        return;
      }
      setResponseFromAPI(true);
      try {
        await executeChangeFileSelection(chatData, file, selectingLegacy);
      } catch (e: any) {
        console.log('Error is here');
        console.error(e);
        setResponseFromAPI(false);
        setUploadedFiles(() => [...uploadedFiles]);
      }
    },
    [executeChangeFileSelection, responseFromAPI, setResponseFromAPI, uploadedFiles]
  );

  const value = useMemo(
    () => ({
      changeFileSelection,
      filesInUploadReports,
      isUploadingFile,
      uploadedFiles,
      uploadFiles,
      uploadingProgress,
      showFileUploads
    }),
    [
      changeFileSelection,
      filesInUploadReports,
      isUploadingFile,
      showFileUploads,
      uploadFiles,
      uploadedFiles,
      uploadingProgress
    ]
  );

  useEffect(() => {
    setShowFileUploads(() => getShowFileUploadsValue(chat?.llm));
  }, [chat]);

  useEffect(() => {
    setUploadedFilesByChat(chat);
  }, [chat, chat?.id, setUploadedFilesByChat]);

  useEffect(() => {
    if (!showFileUploads) {
      return () => {};
    }
    function onDragEnter(e: DragEvent) {
      e.preventDefault();
      setFilesHover(() => true);
    }

    if (fileDragEnterRef?.current) {
      fileDragEnterRef.current.addEventListener('dragenter', onDragEnter);
    }

    return () => {
      if (fileDragEnterRef?.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        fileDragEnterRef.current.removeEventListener('dragenter', onDragEnter);
      }
    };
  }, [fileDragEnterRef, showFileUploads]);

  useEffect(() => {
    setFilesInUploadReports(() => reportsCollections.map((collection) => collection.displayedUpload));
  }, [reportsCollections]);

  useEffect(() => {
    if (filesInUploadReports.some((reports) => !reports.failed)) {
      let overallTotal = filesInUploadReports.reduce((result, reports) => {
        const [, total] = reports.progress;
        return (total || 1) * result;
      }, filesInUploadReports.length);
      const doneList = filesInUploadReports.map((reports, reportIndex, reportsList) => {
        const [reportDone] = reports.progress;
        return reportsList
          .map((reports) => reports.progress)
          .filter(([done, total], index) => total !== 0 && index !== reportIndex)
          .reduce((result, [done, total], index) => {
            return result * total;
          }, reportDone);
      });
      const overallDone = doneList.reduce((result, done) => result + done, 0);
      setUploadingProgress(() => progressPercents(overallDone, overallTotal));
    } else {
      setUploadingProgress(() => -1);
    }
  }, [filesInUploadReports]);

  useEffect(() => {
    if (!showFileUploads) {
      return () => {};
    }
    function onInputAreaDragLeave(e: DragEvent) {
      e.preventDefault();
      setFilesHover(() => false);
    }

    function onInputAreDragOver(e: DragEvent) {
      e.preventDefault();
    }

    function onInputAreDragDrop(e: DragEvent) {
      e.preventDefault();
      setFilesHover(() => false);
      if (e.dataTransfer?.items) {
        const fileItems = [...e.dataTransfer.items].filter(({ kind }) => kind === 'file');
        if (fileItems.length) {
          const files: Array<File> = fileItems
            .map((item) => item && item.getAsFile())
            .filter((item) => item !== null) as Array<File>;

          uploadFiles(files)
            .then(() => {})
            .catch((error) => {
              console.error(error);
            });
        }
      } else {
        console.log('nothing was dropped');
      }
    }

    if (fileDropRef.current) {
      fileDropRef.current.addEventListener('dragleave', onInputAreaDragLeave);
      fileDropRef.current.addEventListener('dragover', onInputAreDragOver);
      fileDropRef.current.addEventListener('drop', onInputAreDragDrop, false);
    }

    return () => {
      if (fileDropRef.current) {
        fileDropRef.current.removeEventListener('dragleave', onInputAreaDragLeave);
        fileDropRef.current.removeEventListener('dragover', onInputAreDragOver);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        fileDropRef.current.removeEventListener('drop', onInputAreDragDrop, false);
      }
    };
  }, [uploadFiles, fileDropRef, showFileUploads]);

  return (
    <ChatFileUploadContext.Provider value={value}>
      <div className={clsx('file-drag-enter', filesHover && 'file-hover')} ref={fileDragEnterRef}>
        <div className={clsx('file-dropzone')} ref={fileDropRef}>
          <div className="file-dropzone-label">Drop files here</div>
        </div>
        {children}
      </div>
    </ChatFileUploadContext.Provider>
  );
}
