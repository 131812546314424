import React, { useRef, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import ChatLog from './ChatLog';
import InputArea from './InputArea';
import LLMSwitcher from './LLMSwitcher';
import { ChatFileUploadProvider } from '../context/ChatFileUpload';
import { SelectedChatContext } from '../context/SelectedChatContext';
import { useRequest } from '../hooks/useRequest';
import { selectScrollFollowsResponse } from '../data/makeRequest';

function ChatArea() {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const logRef = useRef<HTMLDivElement>(null);
  const { selectedChat: chat } = useContext(SelectedChatContext);
  const { stopScrollFollowing, startScrollFollowing } = useRequest();
  const scrollFollowsResponse = useSelector(selectScrollFollowsResponse);

  useEffect(() => {
    if (logRef.current && wrapperRef.current && chat && scrollFollowsResponse) {
      if (scrollFollowsResponse) {
        const scrollPlace = chat.chatLog?.length > 0 ? 'end' : 'start';
        logRef.current.scrollIntoView({
          block: scrollPlace
        });
      }
    }
  }, [logRef, wrapperRef, chat, scrollFollowsResponse]);

  useEffect(() => {
    function listenToScroll(e: any) {
      if (e.deltaY < 0) {
        stopScrollFollowing();
      }
    }

    if (wrapperRef?.current) {
      const chatLogBlock = wrapperRef.current;
      chatLogBlock.addEventListener('wheel', listenToScroll);

      return () => {
        try {
          chatLogBlock.removeEventListener('wheel', listenToScroll);
          // document.removeEventListener('visibilitychange', visibilityChange);
        } catch (e: any) {
          console.log('Error removing scroll.');
        }
      };
    }
  }, [stopScrollFollowing, wrapperRef]);

  useEffect(() => {
    startScrollFollowing();
  }, [chat?.id, startScrollFollowing]);

  return (
    <ChatFileUploadProvider>
      <div className="chatLogWrapper" ref={wrapperRef}>
        <div ref={logRef}>
          <ChatLog />
        </div>
      </div>
      <div className="mainInputTools">
        <InputArea />
        <LLMSwitcher />
      </div>
    </ChatFileUploadProvider>
  );
}

export default ChatArea;
