import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';

import { OKTA_AUTH_ENABLED } from './utils/auth';
const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const ISSUER = process.env.REACT_APP_OKTA_ISSUER;
const BASENAME = process.env.PUBLIC_URL || '';
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;

type OktaConfig = {
  oidc: OktaAuthOptions;
};

export const oktaConfig: OktaConfig = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER as string,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    maxClockSkew: 300,
    services: {
      autoRenew: true,
      autoRemove: true,
      syncStorage: true
    },
    tokenManager: {
      expireEarlySeconds: 25
    }
  }
};

export const oktaAuth = OKTA_AUTH_ENABLED ? new OktaAuth(oktaConfig.oidc) : undefined;
